body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* TODO remove obsolete styling */
fieldset {
  border: 1px solid #dee2e6;
  border-radius: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 17px;
}

fieldset legend {
  font-size: 14px;
  float: none;
  width: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
