@use 'variables';

.MuiGrid-root.page-wrapper {
  background-color: variables.$background-color;
  color: variables.$light-text-color;
  min-height: 90vh;
  margin-top: 8px;
}

.MuiGrid-root.container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;

  // @media (min-width: 1200px) and (max-width: 1399.98px) {
  //   max-width: 1140px;
  // }
  // @media (min-width: 992px) and (max-width: 1199.98px) {
  //   max-width: 960px;
  // }
  // @media (min-width: 768px) and (max-width: 991.98px) {
  //   max-width: 720px;
  // }
  // @media (min-width: 576px) and (max-width: 767.98px) {
  //   max-width: 540px;
  // }
  // @media (max-width: 575.98px) {
  //   max-width: 100%;
  // }
}

.MuiAppBar-root.primary {
  padding-left: 0;
  padding-right: 0;
}
