@use '../default/variables.scss';

.MuiAppBar-root.primary-blue {
  background-color: variables.$blue-secondary;
  color: variables.$blue-text-color;
}

.MuiAvatar-root.primary-blue {
  background-color: #bdbdbd;
}
