@use 'variables';

.MuiButton-root.btn-secondary {
  color: variables.$dark-text-color;
  background-color: variables.$dark-secondary;
}

.primary {
  background-color: variables.$light-secondary;
  color: variables.$light-text-color;
}

.primary-btn {
  background-color: #daeaf6;
}

.MuiPaper-root.simple-card {
  border-color: variables.$dark-secondary;
  color: variables.$light-text-color;
  width: 100%;
  align-items: center;
  text-align: center;

  .card-content.MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
}

.select {
  max-width: 200px;
  width: 100%;
  float: right;

  .select-menu__control,
  .select-menu__menu {
    background-color: variables.$light-secondary;
  }

  .select-menu__single-value {
    color: variables.$light-text-color;
  }

  .select-menu__option {
    color: variables.$light-text-color;

    &--is-focused {
      background-color: variables.$dark-secondary;
      color: variables.$dark-text-color;
    }

    &--is-selected {
      background-color: variables.$dark-secondary;
      color: variables.$dark-text-color;
    }
  }
}

.highlighter {
  display: flex;
  justify-content: flex-start;
}

.nav-link {
  text-decoration: none;
  color: variables.$dark-text-color;
}

.nav-link-mobile {
  text-decoration: none;
  color: black;
}

.card-button {
  border: 2px black solid;
}

.theme-date-picker-wrapper .MuiTextField-root:first-child {
  width: 100%;
}
