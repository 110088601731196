@use '../default/variables.scss';

.MuiButton-root.btn-secondary-dark {
  color: variables.$light-text-color;
  background-color: variables.$light-secondary;
}

.primary-dark {
  background-color: variables.$dark-secondary;
  color: variables.$dark-text-color;

  .MuiTableCell-root {
    color: variables.$dark-text-color;
  }
}

.MuiPaper-root.simple-card-dark {
  border-color: variables.$light-secondary;
  color: variables.$light-text-color;
}

.select-dark {
  color: variables.$dark-text-color;

  .select-menu-dark__control,
  .select-menu-dark__menu {
    background-color: variables.$light-secondary;
  }

  .select-menu-dark__indicator-separator {
    background-color: variables.$dark-secondary;
  }

  .select-menu-dark__indicator.select-menu-dark__dropdown-indicator {
    color: variables.$dark-secondary;
  }

  .select-menu-dark__single-value {
    color: variables.$light-text-color;
  }

  .select-menu-dark__option {
    color: variables.$light-text-color;

    &--is-focused {
      background-color: variables.$dark-secondary;
      color: variables.$dark-text-color;
    }

    &--is-selected {
      background-color: variables.$dark-secondary;
      color: variables.$dark-text-color;
    }
  }
}

.nav-link-dark {
  text-decoration: none;
  color: variables.$light-text-color;
}

.nav-link-mobile-dark {
  text-decoration: none;
  color: black;
}
