$primary-color: #000000;
$secondary-color: #9b9b9b;

$accent-color: #979797;
$background-color: whitesmoke;
$primary-text-color: #000000;

$light-primary: white;
$light-secondary: #ddc8c4;
$dark-primary: #bfbfbf;
$dark-secondary: #6f6f6f;
$light-text-color: #13070c;
$dark-text-color: whitesmoke;

$blue-text-color: white;
$blue-primary: #a7c7e7;
$blue-secondary: #3d426b;
