@use 'variables';

.text-center {
  text-align: center;
}

.form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@for $i from 0 to 30 {
  .mb-#{$i} {
    margin-bottom: $i * 4 + px;
  }

  .mt-#{$i} {
    margin-top: $i * 4 + px;
  }

  .mtb-#{$i} {
    margin-bottom: $i * 4 + px;
    margin-top: $i * 4 + px;
  }

  .pb-#{$i} {
    padding-bottom: $i * 4 + px;
  }

  .pt-#{$i} {
    padding-top: $i * 4 + px;
  }

  .ptb-#{$i} {
    padding-bottom: $i * 4 + px;
    padding-top: $i * 4 + px;
  }
}
