@use 'variables';

.MuiAppBar-root.primary {
  background-color: variables.$background-color;
  color: variables.$light-text-color;
  padding: 10px;
}

.MuiAvatar-root.primary {
  background-color: variables.$light-text-color;
}
