@use '../default/variables.scss';

.MuiAppBar-root.primary-dark {
  background-color: variables.$dark-secondary;
  color: variables.$dark-text-color;
}

.MuiAvatar-root.primary-dark {
  background-color: #bdbdbd;
}
